@font-face {
  font-family: Aniyah;
  src: url('../../../public/fonts/Aniyah.otf')
}

@font-face {
  font-family: Dream-Avenue;
  src: url('../../../public/fonts/Dream-Avenue.ttf')
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Roboto', sans-serif; */

  line-height: 1.2;
  font-weight: 400;
  margin: auto 6%;
  /* background-color: #0B8275; */
  background: url('../images/feathers.jpeg') no-repeat bottom;
  background-attachment: fixed;
  background-size: cover;
}

ul,
ol {
  padding-left: 20px;
  margin-top: 10px;
}

ul li,
ol li {
  margin-bottom: 10px;
}

table,
td,
th {
  border: 1px solid;
  padding: 10px
}

table {
  border-collapse: collapse;
}

blockquote {
  font-style: italic;
  font-weight: 500;
  padding: 2px 8px 2px 75px;
  position: relative;
}

blockquote::before {
  content: '';
  background-image: url("../images/key.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  display: block;
  position: absolute;
  left: 0;
}

blockquote p:last-of-type {
  margin-bottom: 0;
}


.login-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 25px;
}

.container {
  max-width: 1700px;
  margin: 0 auto;
  padding: 0 15px;
}

.no-selection {
  user-select: none;
}

.no-selection::selection {
  background: none;
}

.no-selection::-moz-selection {
  background: none;
}


/* /////////////////////////////////////// */
/* LOG IN */
/* /////////////////////////////////////// */

.form-container {
  display: flex;
  gap: 10px;
  /* margin-top: 75px; */
  margin-top: 30px;
  border-radius: 20px;
  overflow: hidden;
}

.form-container_login {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 45%;
  background-color: #F4F4F4;
  padding-top: 27px;
  padding-bottom: 40px;
  /* padding-top: 80px;
    padding-bottom: 80px; */
  padding-left: 60px;
  padding-right: 60px;
  /* box-shadow: 0px 0px 13.4px rgb(0 0 0 / 54%); */
}

.form-container_login h1 {
  font-size: 55px;
  font-weight: 400;
  margin-bottom: 11px;
  color: #471D07;
}

.form-container_login h4 {
  font-family: Aniyah;
  font-size: 25px;
  font-weight: 400;
  color: #FF8471;
  margin-left: auto;
  margin-bottom: 48px;
}


.form-btn-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  border-radius: 5px;
  background-color: #AEAEAE;
}


.btn {
  display: flex;
  width: 50%;
  align-items: center;
  padding: 20px 0;
  border-radius: 5px;
  color: #F4F4F4;
  font-size: 16px;
  justify-content: center;
  transition: all 0.3s;
}

.btn:hover,
.btn:active {
  opacity: 60%;
}

.btn.active {
  background-color: #0B8275;
}

.new-user-btn {
  background-color: #AEAEAE;
}

.form-muted {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.mt50 {
  margin-top: 50px;
}

.form-muted p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.form-muted a,
.form-muted span {
  font-size: 16px;
  color: #242424;
  margin-bottom: 10px;
  transition: all 0.3s;
  display: block;
  text-align: center;
}

.form-muted a:hover {
  opacity: 60%;
}

.form-cta-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 12px;
}

.form-cta {
  width: 100%;
  display: flex;
  flex-direction: column;
}


.form-cta label {
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.form-cta input {
  font-size: 16px;
  padding: 20px;
  border: 1px solid #AEAEAE;
  border-radius: 5px;
  outline: none;
  /* transition: all 0.3s; */
}

.form-cta input:focus {
  border: 1px solid #777777;
}

.form-cta input::placeholder {
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  color: #AEAEAE;
}

.forgot-password {
  font-size: 15px;
  font-style: italic;
  color: #242424;
  text-decoration: underline;
  margin: 0 auto;
  margin-bottom: 32px;
  transition: all 0.3s;
}

.forgot-password:hover {
  opacity: 60%;
}

/* .login-btn_primary, */
.mainBtn {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 23px;
  margin: 0 auto;
  background-color: #0b8275;
  color: #fff;
  padding: 20px;
  width: 100%;
  min-width: 220px;
  max-width: 350px;
  border-radius: 10px;
  border: none;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.3s;
}

.login-btn_primary:hover {
  opacity: 80%;
}

.mainBtn::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #fff;
}

.form-container_img {
  display: flex;
  flex-grow: 1;
  gap: 10px;
}

.form-container_img img {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}


/* /////////////////////////////////////// */
/* SIGN IN */
/* /////////////////////////////////////// */

.login-btn.signin-bnt {
  background-color: #AEAEAE;
}


.pay-card-img {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 55px auto 30px auto;
}


/* /////////////////////////////////////// */
/* RECOVER */
/* /////////////////////////////////////// */

.login-btn_primary.recover-btn {
  margin-top: 138px;
  margin-bottom: 31px;
}

/* /////////////////////////////////////// */
/* FOOTER */
/* /////////////////////////////////////// */

.footer {
  display: flex;
  justify-content: center;
  margin-top: 38px;
  margin-bottom: 31px;
}

.footer p {
  color: #C9C9C9;
  font-size: 16px;
}

/* /////////////////////////////////////// */
/*HOME */
/* /////////////////////////////////////// */


/* .header.header-none {
    display: none;
  } */

/* .header.header-normal {
    display: block;
  } */

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.logo {
  width: 115px;
  margin: 0 auto 20px;
}

.logo.center {
  margin: 20px auto;
}

.language {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  gap: 20px;
  padding: 14px 23px;
  border-radius: 10px;
  background-color: #8BE5DC;
  max-width: fit-content;
}

.language div {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #242424;
  cursor: pointer;
}

.language div.active {
  color: #5dbeb4;
  cursor: default;
}

.linie {
  border-left: 1px solid #5DBEB4;
  height: 20px;
}

.header .language {
  position: absolute;
  right: 6%;
  margin-right: 15px;
}

/* ------Page Content------ */

.page-content {
  min-height: 80vh;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 35%);
}

/* ------Left NavBar------ */

.left-navbar {
  background-color: #fff;
  background-color: #fff;
  padding: 30px 30px 0 30px;
  /* width: 32%; */
  /* width: 470px; */
  width: 430px;
  position: relative;
}

.left-navbar_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  margin-bottom: 30px;
  margin-left: 26px;
}

.left-navbar_title h4 {
  font-size: 24px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-navbar_linie,
.content_linie {
  border-bottom: 1px solid #AEAEAE;
  width: 100%;
  margin-bottom: 35px;
}

.left-navbar_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px;

  position: relative;
  cursor: pointer;
}

.left-navbar_container::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  width: 1px;
  height: 100%;
  background-color: #E3E3E3;
}

.left-navbar_container:last-of-type {
  background: red;
}

.last::after {
  content: none;
}

.left-navbar_container.is-active {
  background-color: #D0EFF1;
  border-left: 7px solid #04655B;
  border-radius: 5px;
}

.nav-bar_item {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.nav-bar_item svg {
  filter: grayscale(1) brightness(0.5);
}

.left-navbar_container.is-active .nav-bar_item svg {
  filter: unset;
}

.nav-bar_item p {
  font-size: 20px;
  font-weight: 500;
  color: #242424;
}

.inactive .nav-bar_item p {
  color: #c0c0c0;
}

.is-active .nav-bar_item p {
  color: #04665d;
}


/* .left-navbar_container_linie {
    position: absolute;
    border-right: 1px solid red;
    top: 50%;
    right: 5%;
    height: 100%;
    
  } */

.check-container {
  position: relative;
  z-index: 3;
  right: 8px;
}

.check-container p {
  font-size: 14px;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.check-container.is-complete p {
  display: none;
}

.check-container svg {
  font-size: 14px;
  display: none;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.check-container.is-complete svg {
  display: block;
}

.check-container::after,
.check-container::before {
  content: "";
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.check-container::after {
  width: 30px;
  height: 30px;
  background-color: #E3E3E3;
  z-index: -2;
}

.check-container.is-complete::after {
  background-color: #0B8275;
}

.check-container.is-complete::before {
  z-index: -1;
  width: 24px;
  height: 24px;
  background-color: #0B8275;
  border: 2px solid #E3E3E3;
}

.left-navbar_logout {
  position: absolute;
  bottom: 52px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.left-navbar_logout-p {
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 30px;
}


.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 11px 0;
  width: 100%;
  border: 1px solid #04655B;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.logout-btn:hover {
  background-color: #e6fcf5;
}

.logout-btn p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #04655B;
}

.logout-btn svg {
  flex-shrink: 0;
}

/* ------Content------ */
.content {
  background-color: #F4F4F4;
  width: 74.3%;
  /* width: 72%; */
  padding: 33px 33px 0 33px;
}

.content-header h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 14px;
}

.content-header p {
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 30px;
}

/*---GENDER---  */

.content-gender {
  margin-bottom: 25px;
}

.content-gender .radio-btn {
  min-width: 120px;
}

.content-gender p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.radio-btn {
  display: inline-block;
  padding: 16px 15px;
  background-color: #fff;
  border: 1px solid #AEAEAE;
  border-radius: 5px;
  margin-right: 15px;
  font-size: 14px;
}

.radio-btn.is-active {
  border: 1px solid #0B8275;
}

.radio-btn input[type="radio"] {
  display: none;
}

.radio-btn input[type="radio"]+label {
  display: inline-block;
  position: relative;
  margin-left: 21px;
}

.radio-btn input[type="radio"]+label:before {
  content: '';
  position: absolute;
  width: 13px;
  height: 13px;
  background-color: #fff;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #AEAEAE;
}

.radio-btn.is-active input[type="radio"]+label:before {
  background-color: #F5F5F6;
  border: 1px solid #0B8275;
}

.radio-btn input[type="radio"]+label:before {
  border-radius: 50%;
}

.radio-btn input[type="radio"]:checked+label:after {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  background-color: #0B8275;
  left: -18px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #fff;
  border-radius: 50%;
}

/* .content-gender_items input[type="radio"]:checked+label:after {
    border-radius: 50%;
  } */

/*---AGE---  */

.content-age {
  margin-bottom: 25px;
}

.content-age p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}


.content-age select {
  padding: 16px 15px;
  color: #AEAEAE;
  border: 1px solid #AEAEAE;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  font-style: italic;
  width: 20%;
  min-width: 200px;
  display: inline-block;
}

.content-age select option {
  color: #000;
}


select {
  appearance: none;
  background-image: url("../images/arrow-logo.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 17px) center;
}





/*---EDUCATION LEVEL---  */

.content-education {
  margin-bottom: 60px;
}

.content-education p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.content-education select {
  padding: 16px 15px;
  color: #AEAEAE;
  border: 1px solid #AEAEAE;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  font-style: italic;
  width: 40%;
}

.content-education select option {
  color: #000;
}

/*---USE OF MEDICATION---  */

.content-medication {
  margin-bottom: 25px;
}

.content-medication h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.content-medication p {
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 15px;
}

.checkbox-btn {
  display: inline-block;
  padding: 16px 15px;
  background-color: #fff;
  border: 1px solid #AEAEAE;
  border-radius: 5px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.checkbox-btn.is-active {
  border: 1px solid #0B8275;
}

.checkbox-btn input[type="checkbox"] {
  display: none;
}

.checkbox-btn input[type="checkbox"]+label {
  display: inline-block;
  position: relative;
  margin-left: 21px;
}

.checkbox-btn input[type="checkbox"]+label:before {
  content: '';
  position: absolute;
  width: 13px;
  height: 13px;
  background-color: #fff;
  left: -20px;
  top: 1px;
  border: 1px solid #AEAEAE;
  border-radius: 4px;
}

.checkbox-btn.is-active input[type="checkbox"]+label:before {
  background-color: #F5F5F6;
  border: 1px solid #0B8275;
}

/* .checkbox-btn input[type="checkbox"]+label:before {
    border-radius: 50%;
  } */

.checkbox-btn input[type="checkbox"]:checked+label:after {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  background-color: #0B8275;
  left: -18px;
  top: 3px;
  border: 1px solid #fff;
  border-radius: 4px;
}

/*---CULTUTAL OF BACKGROUND---  */
.content-cultural {
  margin-bottom: 40px;
}

.content-cultural p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.content-cultural select {
  padding: 16px 15px;
  color: #242424;
  border: 1px solid #AEAEAE;
  border-radius: 5px;
  background-color: #fff;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  width: 40%;
}

.content-cultural select option {
  color: #242424;
}

/*---SECTION QUESTIONS---  */
.content-questions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 72px;
}

.content-questions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tooltip-info {
  position: relative;
  margin-left: auto;
}

.tooltip-info_container {
  position: absolute;
  bottom: 38px;
  right: -30px;
  background-color: #B6910A;
  color: #FFF;
  width: 600px;
  padding: 40px 20px;
  border-radius: 8px;
  margin-bottom: 16px;
  transition: all 0.3s;

  line-height: 1.4;
}

.tooltip-info_container::after {
  content: "";
  position: absolute;
  height: 15px;
  border: 10px solid transparent;
  border-top-width: 17px;
  bottom: -27px;
  right: 28px;
  border-top-color: #B6910A;
}

.tooltip-info:hover .tooltip-info_container {
  opacity: 1 !important;
  pointer-events: auto !important;
  transform: translate(0, 0) !important;
}

.content-questions-item1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 50%;
}

.content-questions-item1 p {
  font-size: 16px;
  font-weight: 500;
}


/* .content-questions-item2 {
    width: 50%;
  } */

.content-questions-item2 .radio-btn {
  width: 110px;
}

.content-questions_items {
  width: 120px;
  display: inline-block;
  padding: 16px 15px;
  background-color: #fff;
  border: 1px solid #AEAEAE;
  border-radius: 5px;
  margin-right: 15px;
  font-size: 18px;
}

/* .content-questions_items.is-active {
    border: 1px solid #0B8275;
  }
  
  .content-questions_items input[type="radio"] {
    display: none;
  }
  
  .content-questions_items input[type="radio"]+label {
    display: inline-block;
    position: relative;
    margin-left: 21px;
  }
  
  .content-questions_items input[type="radio"]+label:before {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    background-color: #fff;
    left: -20px;
    top: 1px;
    border: 1px solid #AEAEAE;
  }
  
  .content-questions_items.is-active input[type="radio"]+label:before {
    background-color: #F5F5F6;
    border: 1px solid #0B8275;
  }
  
  .content-questions_items input[type="radio"]+label:before {
    border-radius: 50%;
  }
  
  .content-questions_items input[type="radio"]:checked+label:after {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: #0B8275;
    left: -18px;
    top: 3px;
    border: 1px solid #fff;
    border-radius: 50%;
  } */


.content-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 50px;
  gap: 20px;
}

.content-buttons_warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 54px;
  padding: 20px;
  border-radius: 5px;
  gap: 15px;
  border: 1px solid #970815;
  /* margin-bottom: 20px; */
}

.content-buttons_warning p {
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  color: #970815;
}

.content-buttons_container {
  display: flex;
  margin-left: auto;
  gap: 34px;
  /* gap: 40px; */
}


.content-buttons_container_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: #AEAEAE;
  border: none;
  padding: 20px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.content-buttons_container_btn:hover {
  opacity: 70%;
}

.content-buttons_container_btn.container_btn-red {
  background-color: #970815;
}

.content-buttons_container_btn p {
  font-size: 16px;
}

/* +++++++++++++ */
/* TEST POPUP */
/* +++++++++++++ */

.popup-container {
  position: fixed;
  min-height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .4);
  z-index: 20;
  display: none;
}


.popup-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--full-height);
}


.popup-box {
  max-width: 900px;
  width: 100%;
  /* height: 90vh; */
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  max-height: calc(var(--full-height) - 100px) !important;
}

.popup-box_header {
  padding: 17px 130px 17px 40px;
  background-color: #0B8275;
  position: sticky;
  top: 0;
  z-index: 2;
}

.popup-box_header h4 {
  font-size: 20px;
  color: #fff;
}

.popup-box_item {
  padding: 30px 40px;
}

.box-item_video {
  width: 100%;
  height: 400px;
  margin: 0px auto 28px auto;
}

.box-item_video iframe {
  width: 100%;
  height: 100%;
}

.box-item_text {
  font-size: 16px;
  margin-bottom: 70px;
}


.btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

}

.popup-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
  background-color: #471D07;
  border: none;
  color: #fff;
  border-radius: 10px;
  transition: all 0.3s;
}

.popup-btn:hover {
  opacity: 70%;
}

.popup-btn p {
  font-size: 16px;
}

.popup-btn-close {
  font-size: 13px;
  font-weight: 300;
  font-style: italic;
  color: #242424;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.3s;
}

.popup-btn-close-inline {
  color: #FFFFFF;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  margin: auto 0px auto 0px;
  max-width: 100px;
  text-align: center;
  display: table;
}

.popup-btn-close:hover {
  opacity: 70%;
}

/* ------POPUP 2------*/

.question-dropdown {
  display: block;
}

/* .popup-container.is-step-2 .popup-box {
    height: 90vh;
  } */

.popup-box_questions {
  height: 70vh;
  padding-right: 30px;
}

.question-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 40px;
}


.question-item_text {
  display: flex;
  justify-content: space-between;
}


.question-item_text h5 {
  font-size: 17px;
  font-weight: 500;
}

.question-item_text h5 em {
  font-size: 15px;
  font-weight: 300;
}

.question-dropdown p {
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 20px;
}

.question-item_option_container .radio-btn {
  margin-bottom: 24px;
}

.question-item_option_container .checkbox-btn {
  margin-bottom: 15px;
}

/* ------POPUP 3------*/
.save-questions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
}

.save-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30%;
}

.save-questions :first-child {
  margin-bottom: 7px;
}

.save-questions p {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.save-questions-container .popup-btn-close {
  text-align: center;
  margin-top: 20px;
}

/* /////////////////////////////////////// */
/* QUESTIONNAIRES */
/* /////////////////////////////////////// */

.questionnaires-title {
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  border-bottom: 0.5px solid #AEAEAE;
  padding-bottom: 11px;
  margin-top: 16px;
  margin-bottom: 25px;
}

.questionnaires-container {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 25px;
  margin-left: 27px;
}

.questionnaires-container p {
  font-size: 18px;
  font-weight: 500;
}

.questionnaires-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
}

.questionnaires-text span {
  font-size: 16px;
  color: #FF8471;
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
}

.questionnaires-text span:hover {
  opacity: 60%;
}

.check svg {
  width: 16px;
  display: none;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.check {
  position: relative;
  z-index: 3;
}

.check.is-complete svg {
  display: block;
}

.check::after,
.check::before {
  content: "";
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.check::after {
  width: 30px;
  height: 30px;
  /* border: 1px solid #0B8275; */
  background-color: #E3E3E3;
  z-index: -2;
}

.check::before {
  border: 1px solid #fff;
  width: 25px;
  height: 25px;
}

.check.is-complete::after {
  background-color: #0B8275;
}

.check.is-complete::before {
  z-index: -1;
  width: 24px;
  height: 24px;
  background-color: #0B8275;
  border: 2px solid #E3E3E3;
}

.questionnaires-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
  background-color: #AEAEAE;
  border: none;
  padding: 21px 113px;
  color: #fff;
  border-radius: 10px;

  margin-top: 240px;
  margin-bottom: 40px;
  float: right;
  transition: all 0.3s;
}

.questionnaires-btn:hover {
  opacity: 70%;
}

/* /////////////////////////////////////// */
/* REPORT */
/* /////////////////////////////////////// */

.report-title {
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  border-bottom: 0.5px solid #AEAEAE;
  padding-bottom: 11px;
  margin-top: 16px;
  margin-bottom: 25px;
}

.report-container_text {
  background-color: #fff;
  border-radius: 10px;
  padding: 33px 30px;
  height: 600px;
}

.report-container_text .title {
  font-weight: 600;
}

.report-container_text * {
  font-size: 16px;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif !important;
}

.report-container_text img {
  max-width: 100%;
  height: auto;
}

.report-container_text h1,
.report-container_text h2 {
  font-size: 25px;
  font-weight: 500;
  color: #471D07;
  text-align: center;
}

.report-container_text h2 {
  font-size: 20px;
  text-align: left;
}

.report-container_text table * {
  border-color: #AEAEAE;
}

.dark-scrollbar::-webkit-scrollbar,
.dark-scrollbar>div::-webkit-scrollbar {
  width: 8px;
}

.dark-scrollbar {
  overflow: auto;
}

.dark-scrollbar::-webkit-scrollbar-track,
.dark-scrollbar>div::-webkit-scrollbar-track {
  background: #D1D1D1;
  box-shadow: inset 0 0 0 2px white;
}

.dark-scrollbar::-webkit-scrollbar-thumb,
.dark-scrollbar>div::-webkit-scrollbar-thumb {
  background: #242424;
  border-radius: 7px;
}

.dark-scrollbar::-webkit-scrollbar-thumb:hover,
.dark-scrollbar>div::-webkit-scrollbar-thumb:hover {
  background: #242424;
}

.report-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
  background-color: #970815;
  border: none;
  padding: 18px 25px;
  color: #fff;
  border-radius: 10px;
  width: fit-content;

  margin-top: 56px;
  margin-bottom: 40px;
  float: right;
  transition: all 0.3s;
  font-family: 'Montserrat', sans-serif;
}

.report-btn:hover {
  opacity: 70%;
}

.report-container_text_whitecolor {
  background-color: #970815;
  padding: 10px;
}

/* errors */
.errors-container {
  margin-top: 20px;
}

.error {
  padding: 15px 30px 15px 70px;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
  color: #AEAEAE;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.inline-error {
  margin-top: 240px;
  display: inline-block;
}

.error div {
  color: #242424;
  line-height: 20px;
  font-weight: 500;
}

.error::before {
  content: "";
  display: inline-block;
  background-image: url('../images/alert-triangle-white.svg');
  background-repeat: no-repeat;
  background-color: #d01223;
  background-position: center;
  height: 100%;
  width: 60px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px 0px 0px 5px;
}

.error.success::before {
  background-image: url('../images/check-2.svg');
  background-color: #04655B;
  background-size: 30px;
}

.error .hide {
  border: 1px solid #AEAEAE;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
}

input.invalid {
  border-color: red;
  background-image: url('../images/alert-triangle.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-position: 98% center;
}

.profile-details div span:first-of-type,
.profile-details>p {
  margin-right: 10px;
  font-weight: 500;
}