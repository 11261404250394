/* /////////////////////////////////////// */
/* 1100px */
/* /////////////////////////////////////// */
@media (max-width:1100px) {

  /* ------LOG IN------ */

  .form-container_login {
    padding-left: 35px;
    padding-right: 35px;
  }

  .form-container_login h1 {
    font-size: 50px;
  }

  .form-container_login h4,
  .questionnaires-title {
    font-size: 21px;
  }

  .questionnaires-container p {
    font-size: 16px;
  }

  .questionnaires-text span {
    font-size: 14px;
  }

  .btn {
    padding: 17px 0;
  }

  .login-btn_primary {
    gap: 15px;
    padding: 20px 95px;
  }

  /* ------RECOVER------ */
  .login-btn_primary.recover-btn {
    padding: 20px 62px;
  }


  /* ------HOME------ */

  .content-age select {
    width: 25%;
  }

  .content-education select {
    width: 50%;
  }

  .content-medication p {
    margin-bottom: unset;
  }

  .checkbox-btn {
    margin-top: 15px;
  }

}

/* /////////////////////////////////////// */
/* 1000px */
/* /////////////////////////////////////// */

@media (max-width:1000px) {

  /* ------LOG IN------ */
  .form-container_img img:last-child {
    display: none;
  }

  .form-container_login {
    padding-left: 30px;
    padding-right: 30px;
  }

  .form-container_login {
    width: 48%;
  }

  .form-container_login h4 {
    font-size: 20px;
  }

  .form-cta input {
    padding: 17px;
  }


  /* ------Side Bar------ */
  .left-navbar {
    padding: 30px 20px 0 20px;
    width: 355px;
  }


  .left-navbar_title {
    gap: 10px;
    margin-left: 18px;
  }

  .left-navbar_title h4 {
    font-size: 21px;
  }

  .nav-bar_item {
    gap: 12px;
  }

  .nav-bar_item img {
    width: 24px;
  }

  .nav-bar_item p {
    font-size: 17px;
  }

  /* ------HEADER------ */

  .header-title h1 {
    font-size: 50px;
  }

  .header-title h4 {
    font-size: 25px;
  }

  .language {
    gap: 18px;
    padding: 14px 20px;
  }


  /* ------HOME------ */

  .content-header h3 {
    font-size: 22px;
  }

  .content-gender p {
    font-size: 15px;
  }

  .content-gender .radio-btn .content-age p {
    font-size: 15px;
  }

  .content-age select {
    width: 33%;
  }

  .content-education select {
    width: 60%;
  }

  .content-medication h5 {
    font-size: 15px;
  }

  .content-questions-container {
    gap: 15px;
  }

  .content-questions {
    flex-direction: column;
    align-items: unset;
    gap: 10px;
  }

  .content-questions-item1 {
    width: 100%;
  }

  .content-questions-item2 {
    width: 100%;
  }

  .content-buttons_container {
    margin-left: unset;
  }

  .content-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  /* ------Pop up 1------ */

  .popup-box {
    max-width: 800px;
  }

  .popup-box_header h4 {
    font-size: 18px;
  }

  /* ------Pop up 2------ */

  .question-item_text h5 {
    font-size: 22px;
  }

  .question-item_text h5 {
    font-size: 22px;
    /* margin-bottom: 16px; */
  }

  .question-item_option_container .checkbox-btn {
    margin-bottom: 0px;
  }

  /* ------QUESTIONNAIRES------ */

  .questionnaires {
    font-size: 20px;
  }

  .questionnaires-container p {
    font-size: 18px;
  }

  .questionnaires-text {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }


  .questionnaires-title {
    font-size: 18px;
  }

  .questionnaires-container {
    margin-left: 15px;
  }

  .report-btn,
  .questionnaires-btn {
    float: none;
    margin-top: 100px;
  }

  blockquote {
    padding-left: 60px;
  }
}

/* /////////////////////////////////////// */
/* 850px */
/* /////////////////////////////////////// */
@media (max-width:850px) {

  /* ------LOG IN------ */
  .form-container {
    margin-top: 35px;
  }

  .form-container_login h1 {
    font-size: 45px;
  }

  .form-container_login h4 {
    font-size: 19px;
  }

  .form-container_img {
    display: none;
  }

  .form-container_login {
    width: 100%;
    flex-shrink: unset;
  }

  .form-cta input {
    padding: 15px;
  }


  /* ------HEADER------ */
  .header-title h1 {
    font-size: 43px;
  }

  .header-title h4 {
    font-size: 22px;
  }

  /* ------Side Bar------ */

  .nav-bar_item p {
    font-size: 15px;
  }

  /* ------HOME------ */

  .content-cultural select {
    font-size: 16px;
  }


  .radio-btn,
  .checkbox-btn,
  select {
    padding: 14px 15px !important;
  }

  .radio-btn {
    font-size: 15px;
  }

  .checkbox-btn {
    font-size: 15px;
  }

  .content-age select {
    width: 40%;
  }

  .content-header h3 {
    font-size: 19px;
  }

  .content-gender p {
    font-size: 14px;
  }

  .content-education select {
    width: 70%;
  }

  /* ------Pop up 1------ */

  .popup-box {
    max-width: 96vw;
    margin: 0 auto;
    height: 80vh;
    padding-bottom: 20px;
  }

  /* ------Pop up 2------ */

  .popup-box_item {
    padding: 25px 30px;
  }

  .popup-box_questions {
    height: 100%;
    padding-bottom: 30px;
    padding-right: 30px;
  }

}

/* /////////////////////////////////////// */
/* 700px */
/* /////////////////////////////////////// */
@media (max-width:700px) {

  /* ------Header------ */
  .header {
    margin-bottom: unset;
    /* align-items: unset; */
  }

  .logo {
    width: 85px;
    margin: 0 0 20px;
  }

  body {
    margin: 0;
  }

  .header .language {
    right: 0;
  }

  .header-title h1 {
    font-size: 40px;
  }

  .header-title h4 {
    font-size: 17px;
    margin-left: 80px;
    margin-bottom: 20px;
  }

  /* ------Side Bar------ */

  .left-navbar_container::after {
    width: 100%;
    height: 1px;
    top: -5px;
    right: -50%;
    margin-right: -8px;
  }

  .left-navbar {
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(3, 1fr); */
    grid-template-columns: repeat(3, minmax(0, 1fr));
    /* justify-items: center; */
    gap: 20px;
  }

  .left-navbar_title {
    grid-row: 1;
    grid-column: span 2;
  }

  .left-navbar_title h4 {
    font-size: 20px;
  }

  .left-navbar_title p {
    padding-bottom: 20px;
  }

  .left-navbar_title img {
    display: none;
  }

  .nav-bar_item svg {
    display: none;
  }

  .nav-bar_item p {
    text-align: center;
    height: 2.5em;
  }

  .left-navbar_linie {
    display: none;
  }


  .left-navbar_container {
    flex-direction: column;
    grid-row: 2;
  }

  .left-navbar_container.is-active {
    background-color: unset;
    border-left: unset;
  }

  .check-container {
    bottom: 55px;
    right: 0;
  }


  .left-navbar_logo {
    display: none;
  }

  .left-navbar_logout {
    position: unset;
    bottom: unset;
    margin-right: unset;
    display: block;

    grid-row: 1;
    grid-column: 3;
  }

  .logout-btn {
    border: none;
    padding: unset;
  }

  .logout-btn:hover {
    background-color: unset;
  }


  .left-navbar_logout-p {
    display: none;
  }


  /* ------Content------ */
  .page-content {
    flex-direction: column;
    height: auto;
  }

  .content {
    width: 100%;
  }

  .content-age select {
    width: 60%;
  }

  .content-education select {
    width: 100%;
  }

  .content-cultural select {
    width: 100%;
  }

  .content-questions-item1 {
    justify-content: space-between;
  }

  .tooltip-info_container {
    width: calc(100vw - 36px);
  }

  /* .tooltip-info_container::after {
    right: 28px;
  } */

  .content-buttons_warning {
    width: 100%;
    justify-content: space-around;
  }


  /* ------Report------ */
  .report-container_text {
    padding: 20px 19px;
  }


}

/* /////////////////////////////////////// */
/* 500px */
/* /////////////////////////////////////// */
@media (max-width:500px) {

  /* ------LOG IN------ */

  .container {
    padding: 0 14px;
  }

  .form-container {
    margin-top: 20px;
  }

  .form-container_login {
    padding-top: 42px;
    padding-bottom: 27px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .form-container_login h1 {
    font-size: 35px;
  }

  .form-container_login h4 {
    font-size: 18px;
  }

  .form-cta label,
  .popup-box_header h4 {
    font-size: 15px;
  }

  .form-cta input {
    font-size: 14px;
  }

  .forgot-password {
    font-size: 14px;
    /* margin-bottom: 72px; */
  }

  /* ------Header------ */
  .header-title h1 {
    font-size: 32px;
  }

  .header-title h4 {
    font-size: 13px;
    margin-left: 51px;
    /* margin-bottom: 36px; */
  }


  /* ------Side Bar------ */

  .left-navbar {
    padding: 20px;
  }

  /* ------Content------ */

  .content {
    padding: 32px 12px;
  }

  .content-header h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .content-header p {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .content-gender,
  .content-age,
  .content-education {
    margin-bottom: 20px;
  }

  .content-gender p,
  .content-age p,
  .content-education p,
  .content-mediaction h5,
  .content-mediaction p,
  .content-cultural p {
    font-size: 14px;
    margin-bottom: 10px;
  }


  .content-questions-item1 p {
    font-size: 15px;
  }

  .tooltip-info_container {
    right: -8px;
    width: calc(100vw - 36px);
  }

  .tooltip-info_container::after {
    right: 6px;
  }


  .content-buttons_container {
    width: 100%;
  }

  .content-buttons_container_btn {
    width: 100%;
    justify-content: center;
  }

  /* ------Pop up 1------ */

  /* .popup-box {
    width: 100vw;
    height: 100vw;
  } */

  /* ------Pop up 2------ */


  .popup-container.is-step-2 .popup-box_questions,
  .popup-container.is-step-3 .popup-box_questions {
    height: 65vh;
  }

  .question-item_text h5 {
    font-size: 17px;
  }

  .content_linie {
    margin-bottom: 20px;
  }

  .question-item,
  .question-item_option_container .radio-btn {
    margin-bottom: 15px;
  }

  .tooltip-info_container {
    padding: 20px 10px;
    font-size: 14px;
  }

  .question-item_option_container .radio-btn,
  .question-item_option_container .checkbox-btn {
    display: block;
  }

  /* .popup-btn-close {
    font-size: 14px;
  } */

  .questionnaires {
    font-size: 18px;
  }

  .questionnaires-container p {
    font-size: 16px;
  }

  .questionnaires-text {
    gap: 6px;
  }

  .popup-box_header {
    padding: 19px 100px 19px 15px;
  }

  .popup-box_item {
    padding: 10px 15px;
  }

  .popup-box_questions {
    padding-right: 6px;
  }

  .popup-btn {
    width: 100%;
  }

  /* ------Pop up 3------ */
  .save-questions {
    align-items: flex-start;
  }

  .save-questions p {
    font-size: 18px;
    text-align: center;
  }

  /* ------Report------ */
  .report-btn,
  .questionnaires-btn {
    width: 100%;
  }

}